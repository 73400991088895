










































































import { ProduccionListado,ObtenerPallet } from '@/entidades/KTB/Produccion/ProduccionQuery';
import {DespachoRequest,GuardarDespacho} from '@/entidades/KTB/Despacho/Despacho';
import { Espesor } from '@/entidades/Maestro/Ktb/Espesor';
import { Largo } from '@/entidades/Maestro/Ktb/Largo';
import {State,Action} from 'vuex-class';
import {Vue,Component} from 'vue-property-decorator'
import { Planta } from '@/entidades/Maestro/Planta';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
@Component({
    name : 'DesapchoView'
})
export default class DesapchoView extends Vue
{
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @State('usuarioId',{namespace: 'authModule'}) usuarioId!:string;
    @State('espesor',{namespace: 'maestroKtb'}) listadoEspesor!:Array<Espesor>;
    @State('largo',{namespace: 'maestroKtb'}) listadoLargo!:Array<Largo>;
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"T2K",sortable:true,value:'t2K'},
            {text:"Hacienda",sortable:false,value:'haciendaName'},
            {text:"Fecha",sortable:false,value:'fecha'},
            {text:"Largo",sortable:false,value:'largoName'},
            {text:"Espesor",sortable:true,value:'espesorName'},
            {text:"# Plantillas",sortable:false,value:'numPlantilla'},
            {text:"Volumen",sortable:true,value:'volumen'},                     
    ];
    listadoProcedencia:Array<any> = [
        {
            'id': 1 , 'descripcion' : "PLANTACIONES"
            
        }
        ,
        {
            'id': 2 , 'descripcion' : "TERCEROS"
        }
    ]
    listadoProduccion:Array<ProduccionListado>=[];
    listadoPalletSeleccionado:Array<ProduccionListado> =[];
    procedenciaId:number=1;
    espesorId:number =0;
    largoId:number =0;

    async obtenerPalletLocal()
    {
        try
        {
            this.changeLoading(new Loading(true,"Obteniendo informacion...Espere por favor"))
            this.espesorId = this.espesorId == null || undefined ? 0 : this.espesorId;
            this.largoId = this.largoId == null || undefined ? 0 : this.largoId;
            var rsp = await ObtenerPallet(this.plantas[0].plantaId,this.espesorId,this.largoId,this.procedenciaId);
            if(rsp.isSuccess == true)
            {
                this.listadoProduccion = rsp.isResult;
            }
            else
            {
                this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            }
        }
        catch(error)
        {

        }
        finally
        {
            this.changeLoading(new Loading(false,""))
        }
    }

    async despachar()
    {
        try
        {
            this.changeLoading(new Loading(true,"Registrando informacion...Espere por favor"));
            let request:DespachoRequest = new DespachoRequest(this.usuarioId,this.plantas[0].plantaId,this.plantas[0].plantaId,0);
            request.items = this.listadoPalletSeleccionado;
            let response = await GuardarDespacho(request);
            if(response.isSuccess == true)
            {
                this.listadoProduccion = [];
                this.changeAlerta(new Alerta(response.isMessage,true,"success",3000));
            }
            else
            {
                this.changeAlerta(new Alerta(response.isMessage,true,"danger",3000));
            }

        }
        catch(error)
        {
            console.log(error);
        }
        finally
        {
            this.changeLoading(new Loading(false,""));
        }
    }

    cambiarBackground(item:any)
    {
        return item.t2K > 8 ? 'red' : '';
    }

    async mounted() 
    {
       // this.listadoLargo = (await ObtenerSelectLargo()).isResult;
        //this.listadoEspesor = (await ObtenerSelectEspesor()).isResult; 
    }
}
