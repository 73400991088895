import { ProduccionListado } from "../Produccion/ProduccionQuery";
import { Save,ObtenerPost2, Obtener } from "@/Generico/Conexion";
import { axiosKtb } from "@/plugins/axios";
import PalletSend from "../Consulta/PalletSend";
export class DespachoRequest 
{
    usuarioId?: string;
    plantaOrigenId?: number;
    plantaDestinoId?: number;
    volumen?: number;
    items?: ProduccionListado[];

     constructor(_usuarioId?:string,_plantaOrigenId?:number,_plantaDestinoId?:number,
                _volumen?:number)
     {
        this.usuarioId = _usuarioId;
        this.plantaOrigenId = _plantaOrigenId;
        this.plantaDestinoId = _plantaDestinoId;
        this.volumen = _volumen;
        this.items = [];
     }
}

export class DespachoReporteResponse 
{
    despachoId!: number;
    fecha!: string;
    estado!: string;
    estadoProceso!: string;
    plantaId!: number;
    plantaName!: string;
    descripcion!:string;
}

export async function GuardarDespacho(request:DespachoRequest)
{
    return Save<DespachoRequest>('despacho/guardar',request,axiosKtb);
}

export async function ObtenerDespachoFecha(desde:string,hasta:string)
{
    return Obtener<DespachoReporteResponse>(`despacho/obtenerdespachofecha/${desde}/${hasta}`,axiosKtb);
}

export async function ObtenerDespachoHistorico(despachoId:number)
{
    return Obtener<DespachoReporteResponse>(`despacho/obtenerhistorico/${despachoId}`,axiosKtb);
}

export async function ObtenerPalletDespacho(despachoId:number)
{
    return Obtener<PalletSend>(`despacho/obtenerpalletDespacho/${despachoId}`,axiosKtb);
}